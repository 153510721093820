import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import PastInvoices from 'sales/PastInvoices';

export default (props) => (
  <Router>
    <Component path="/sales/Past_Invoices/:retailerId/:userId" layoutProps={props} />
  </Router>
);

const Component = ({ layoutProps, retailerId, userId }) => {
  return (
    <Layout {...layoutProps} hideNodeSelector>
      <ReactQueryConfigProvider
        config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
      >
        <PastInvoices retailerId={retailerId} userId={userId} />
      </ReactQueryConfigProvider>
    </Layout>
  );
};
