import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useQuery } from 'react-query';
import BottomNavSales from 'sales/BottomNavSales';
import { useCurrencyFormatter, useDate } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { formatDateHumanReadable, truncate } from 'utils/utils';

const PastInvoices = ({ retailerId, userId }) => {
  const date = useDate();

  const { data, status } = useQuery(date && ['past_5_invoices', { retailerId }], () =>
    fetch(`/salesman/panel/${userId}?retailerId=${retailerId}&date=${date}`)
  );

  if (status === 'loading') return <LinearProgress color="secondary" />;

  return (
    <div class="bg-salesGray p-2">
      <div class="grid divide-y px-3 bg-white m-4 rounded-mismatch divide-gray-300 gap-2 pb-20">
        {data?.pastSales.length > 0 ? (
          data?.pastSales?.map((invoice) => (
            <Invoice invoice={invoice} key={invoice.code}></Invoice>
          ))
        ) : (
          <p class="font-semibold text-grey_secondary text-center text-lg">
            No Invoices present for past two months
          </p>
        )}
      </div>

      <BottomNavSales index={'none'} />
    </div>
  );
};

function Invoice({ invoice }) {
  const formatCurrency = useCurrencyFormatter();

  return (
    <div class="flex justify-between items-center text-grey_secondary pt-2">
      <div>
        <p class="font-semibold text-sm">{truncate(invoice.code)}</p>
        <p class="text-grey text-xs">{formatDateHumanReadable(invoice.date)}</p>
      </div>
      <p class="font-semibold text-base">{formatCurrency(invoice.value)}</p>
    </div>
  );
}

export default PastInvoices;
