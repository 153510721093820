import { BottomNavigation, BottomNavigationAction, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PieChartIcon from '@material-ui/icons/PieChart';
import { navigate } from 'components/Link';
import React, { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useDate } from 'utils/customHooks';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '30px 0 40px 0',
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
  },
  normal: {
    color: 'grey',
  },
  selected: {
    color: 'white',
  },
}));

function BottomNavSales({ index }) {
  const [tab = 'daily'] = useQueryParam('tab', StringParam);

  const [link, setLink] = useState(index || 0);
  const classes = useStyles();
  const date = useDate();

  if (!date) return <LinearProgress color="secondary" />;

  return (
    <BottomNavigation
      value={link}
      onChange={(_, newValue) => {
        setLink(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Dashboard"
        icon={<PieChartIcon style={link === 0 ? { color: 'white' } : { color: 'grey' }} />}
        onClick={() => navigate(`/sales/stlPerformance?tab=${tab}`)}
        showLabel
        classes={{
          root: classes.normal,
          selected: classes.selected,
        }}
      />
      <BottomNavigationAction
        label="Sales Rep View"
        classes={{
          root: classes.normal,
          selected: classes.selected,
        }}
        icon={<AllInboxIcon style={link === 1 ? { color: 'white' } : { color: 'grey' }} />}
        onClick={() => navigate(`/sales/salesRepView?tab=${tab}`)}
        showLabel
      />
      {/* <BottomNavigationAction
        label="Market work"
        classes={{
          root: classes.normal,
          selected: classes.selected,
        }}
        icon={
          <AssignmentTurnedInIcon style={link === 2 ? { color: 'white' } : { color: 'grey' }} />
        }
        onClick={() => navigate(`/sales/audit`)}
        showLabel
      /> */}
      <BottomNavigationAction
        label="Notifications"
        classes={{
          root: classes.normal,
          selected: classes.selected,
        }}
        icon={
          <NotificationsActiveIcon style={link === 3 ? { color: 'white' } : { color: 'grey' }} />
        }
        showLabel
      />
    </BottomNavigation>
  );
}

export default BottomNavSales;
